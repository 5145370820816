import {SpawnBatchItem} from "../spawn-batch/entities";
import {
    spawnBatchItems,
    updateInMemorySpawnBatchItems
} from "../spawn-batch/inmemory";
import {
    newSubstrateBatchV2,
    SubstrateBatchV2
} from "./entities";
import {CreateSubstrateBatchFormValuesV2, UpdateSubstrateBatchFormValuesWithID} from "./query";
import {blankBatchItemStore} from "../blank-batch/inmemory";
import {inMemoryStoreFromArray, newInmemoryStore} from "../inmemory";
import {BlankBatchItem} from "../blank-batch/entities";
import {UpdateSubstrateBatchFormValues} from "../../pages/substrate/edit-form";
import {UpdateSubstrateBatchRequest} from "../../generated-sources/openapi";

// V2 store
export const substrateBatchesV2Store = newInmemoryStore<SubstrateBatchV2>()
substrateBatchesV2Store.add(newSubstrateBatchV2("sb-001", "ba-001", new Date('2023-12-04T00:00:00'), spawnBatchItems[0], [
    blankBatchItemStore.getByIndexUnsafe(0),
    blankBatchItemStore.getByIndexUnsafe(2),
]))
substrateBatchesV2Store.add(newSubstrateBatchV2("sb-002", "ba-002", new Date('2023-12-05T00:00:00'), spawnBatchItems[1], [
    blankBatchItemStore.getByIndexUnsafe(5),
]))

// Update blank batches with spent
const updateBlankBatchItemAsInoculated = (i: BlankBatchItem): BlankBatchItem => {
    i.inoculated = true
    return i
}
blankBatchItemStore.updateAtIndexWith(0, updateBlankBatchItemAsInoculated)
blankBatchItemStore.updateAtIndexWith(2, updateBlankBatchItemAsInoculated)
blankBatchItemStore.updateAtIndexWith(5, updateBlankBatchItemAsInoculated)



export async function inMemGetSubstrateBatchesWithItemsV2(): Promise<SubstrateBatchV2[]> {
    return substrateBatchesV2Store.toArray()
}

export function inMemCreateSubstrateBatchV2({spawnBatchItemId, blankBatchItemIds, createdDate, batchCode}: CreateSubstrateBatchFormValuesV2) {

    // mark spawn batch item as spent
    const blankBatchItems = blankBatchItemStore.find(item => blankBatchItemIds.indexOf(item.id) !== -1)
    if (blankBatchItems.length !== blankBatchItemIds.length) {
        throw new Error(`can not find all blank batch items found ${blankBatchItems.length} expected ${blankBatchItemIds.length}`)
    }


    const spawnBatchItem = inMemoryStoreFromArray<SpawnBatchItem>(...spawnBatchItems).findFirst((item: SpawnBatchItem) => item.id === spawnBatchItemId)
    if (spawnBatchItem === undefined) {

        console.log("authedCreateSubstrateBatch error", spawnBatchItems)

        throw new Error(`can not find spawnBatchItem ${spawnBatchItemId}`)
    }

    updateInMemorySpawnBatchItems(spawnBatchItems.map((item: SpawnBatchItem) => {
        if (item.id === spawnBatchItemId) {
            spawnBatchItem.spent = true
            return spawnBatchItem
        }

        return spawnBatchItem
    }))

    substrateBatchesV2Store.add(newSubstrateBatchV2(`sub-batch-${substrateBatchesV2Store.nextIndex()}`, batchCode, createdDate, spawnBatchItem, blankBatchItems))


    console.log("authedCreateSubstrateBatch created", substrateBatchesV2Store.toArray())
}

export function inMemUpdateSubstrateBatchV2({id, createdDate}: UpdateSubstrateBatchFormValuesWithID) {
    console.log("authedUpdateSubstrateBatch", createdDate)

    // find with ID
    const updated = substrateBatchesV2Store.updateWith( (item: SubstrateBatchV2) => item.id === id, (original: SubstrateBatchV2) => {
        original.createdDate = createdDate

        return original
    })

    if (!updated) {
        throw new Error("did not update")
    }

    console.log("authedUpdateSubstrateBatch created", substrateBatchesV2Store.toArray())
}