import {CreateLiquidCultureBatchFormValues} from "../../pages/liquid-culture-batch/form";
import {
    LiquidCultureBatch,
    LiquidCultureBatchItem,
    newLiquidCultureBatch,
    newLiquidCultureBatchItemPartial,
    newLiquidCultureItemFromIndex
} from "./entities";
import {getCultureStarters} from "../culture-starter/query";
import {CultureStarterByID, cultureStarters} from "../culture-starter/inmemory";
import {autoIncrementID} from "../../lib/uuid/uuid";
import {arrayRange} from "../../lib/collections/collection";
import {findAndReplace} from "../inmemory";




export let liquidCultureBatches:LiquidCultureBatch[] = [
    newLiquidCultureBatch("liquidCulture-id-1", "oy-ba-1", cultureStarters[0],2, new Date(), null, 10,  [
        newLiquidCultureBatchItemPartial("liquidCulture-id-1-1","liquidCulture-id-1-1", false),
        newLiquidCultureBatchItemPartial("liquidCulture-id-1-2","liquidCulture-id-1-2", true),
    ]),
]

export let liquidCultureBatchItems:LiquidCultureBatchItem[] = [
    newLiquidCultureItemFromIndex("liquid-culture-batch-1",1, liquidCultureBatches[0]),
    newLiquidCultureItemFromIndex("liquid-culture-batch-2",2, liquidCultureBatches[0])
]

export const getLiquidCultureBatchData = () => liquidCultureBatches
export const getLiquidCultureByID = (id:string|undefined) => getLiquidCultureBatchData().find(_liquidCulture => _liquidCulture.id === (id || ""))
export const getLiquidCultureBatchItemsData = () => liquidCultureBatchItems
export const getLiquidCultureBatchItemByID = (id:string|undefined) => getLiquidCultureBatchItemsData().find(_liquidCulture => _liquidCulture.id === (id || ""))


export async function inMemGetLiquidCultureBatches(): Promise<LiquidCultureBatch[]> {
    return liquidCultureBatches
}

export async function inMemGetLiquidCultureBatchItems(): Promise<LiquidCultureBatchItem[]> {
    return liquidCultureBatchItems
}

export async function inMemCreateLiquidCultureBatch(create: CreateLiquidCultureBatchFormValues): Promise<void> {
    const numberOfContainers = parseInt(create.numberOfContainers, 10)
    const volume = parseInt(create.volume, 10)

    const {batchCode, cultureStarterID, startDate, disposalDate} = create

    const starter = CultureStarterByID(cultureStarterID)
    if (starter===undefined) {
        console.log("error creating liquid culture cannot find culture starter", getCultureStarters(new Promise(res => res(""))), cultureStarterID)
        throw new Error("inventory create can not find unitType")
    }

    const batch = newLiquidCultureBatch(`liquidCulture-id-${liquidCultureBatches.length+1}`, batchCode, starter , numberOfContainers, startDate, disposalDate, volume)

    // create liquid culture batch
    liquidCultureBatches = [...liquidCultureBatches, batch]

    // create liquid culture item
    const batchItems = arrayRange(numberOfContainers).map((_, i) => newLiquidCultureItemFromIndex(autoIncrementID(), i+1, batch))

    liquidCultureBatchItems = [...liquidCultureBatchItems, ...batchItems]

    console.log("inMemCreateLiquidCultureBatch", liquidCultureBatchItems)
}

export async function inMemUpdateLiquidCultureBatch(update: CreateLiquidCultureBatchFormValues): Promise<void> {
    const numberOfContainers = parseInt(update.numberOfContainers, 10)
    const volume = parseInt(update.volume, 10)

    const {batchCode, cultureStarterID, startDate, disposalDate} = update

    const starter = CultureStarterByID(cultureStarterID)
    if (starter===undefined) {
        console.log("error creating liquid culture cannot find culture starter", getCultureStarters(new Promise(res => res(""))), cultureStarterID)
        throw new Error("inventory create can not find unitType")
    }

    const batch = newLiquidCultureBatch(update.id, batchCode, starter , numberOfContainers, startDate, disposalDate, volume)

    liquidCultureBatches = findAndReplace(liquidCultureBatches, ({id}) => id === update.id , () => batch)
}
