import {CreateSpawnBatchFormValues} from "../../pages/spawn-batch/form";
import {
    emptySpawnBatchDetails,
    newSpawnBatchItem,
    newSpawnBatchItemFromIndex,
    newSpawnBatchItemPartial,
    SpawnBatch,
    SpawnBatchItem, toDetails
} from "./entities";
import {
    getLiquidCultureBatchData,
    liquidCultureBatchItems, getLiquidCultureBatchItemByID
} from "../liquid-culture-batch/inmemory";
import {arrayRange} from "../../lib/collections/collection";
import {autoIncrementID} from "../../lib/uuid/uuid";
import {newSpawnBatch} from "./entities";
import {ref} from "yup";
import {UpdateSpawnBatchItemFormValues} from "./query";


export const inMemSpawnBatchOne = newSpawnBatch(
    "spawn-id-1",
    "sp-batch-1",
    3,
    10,
    "a note",
    new Date('2022-12-23T03:24:00'),
    liquidCultureBatchItems[0],
    [
        newSpawnBatchItemPartial("11", "sp-batch-1-1", false, false),
        newSpawnBatchItemPartial("12", "sp-batch-1-2", false, false),
        newSpawnBatchItemPartial("13", "sp-batch-1-3", false, false),
    ]
)

export const inMemSpawnBatchTwo = newSpawnBatch(
    "spawn-id-2",
    "sp-batch-2",
    3,
    11,
    "a second note",
    new Date('2022-12-22T03:24:00'),
    liquidCultureBatchItems[0],
    [
        newSpawnBatchItemPartial("21", "sp-batch-2-1", false, false),
        newSpawnBatchItemPartial("22", "sp-batch-2-2", false, false),
        newSpawnBatchItemPartial("23", "sp-batch-2-3", true, false),
    ]
)

export let spawnBatches: SpawnBatch[] = [
    inMemSpawnBatchOne,
    inMemSpawnBatchTwo,
    newSpawnBatch(
        "spawn-id-3",
        "sp-batch-3-spent",
        3,
        11,
        "a second note",
        new Date('2022-12-21T03:24:00'),
        liquidCultureBatchItems[0],
        [
            newSpawnBatchItemPartial("31", "sp-batch-2-1", true, false),
            newSpawnBatchItemPartial("32", "sp-batch-2-2", true, false),
            newSpawnBatchItemPartial("33", "sp-batch-2-3", true, false),
        ]
    ),

]

const emptySpawnBatchItems: SpawnBatchItem[] = []

//todo make inmemory store
export let spawnBatchItems = spawnBatches.reduce(
    (acc, batch) => [...acc, ...batch.items.map(({id, reference, spent}) =>
        newSpawnBatchItem(id, reference, toDetails(batch), spent) )],
    emptySpawnBatchItems,
)




export function updateInMemorySpawnBatchItems(items: SpawnBatchItem[]) {
    spawnBatchItems = items
}

export const getSpawnsData = () => spawnBatches
export const getSpawnByID = (id:string|undefined) => getSpawnsData().find(_spawn => _spawn.id === (id || ""))

export const getSpawnBatchItemsData = () => spawnBatchItems
export const getSpawnBatchItemByID = (id:string|undefined) => getSpawnBatchItemsData().find(_liquidCulture => _liquidCulture.id === (id || ""))

export async function inMemGetSpawnBatches(): Promise<SpawnBatch[]> {
    console.log("inMemGetSpawnBatches", spawnBatches)
    return spawnBatches
}

export async function inMemGetSpawnBatchItems(): Promise<SpawnBatchItem[]> {
    return spawnBatchItems
}

export async function inMemCreateSpawn(create: CreateSpawnBatchFormValues): Promise<void> {
    console.log("CreateSpawnFormValues - query", create)

    const numberContainers = parseInt(create.numberContainers, 10)
    const weight = parseInt(create.weightGrams, 10)

    const liquidCultureBatchItem = getLiquidCultureBatchItemByID(create.liquidCultureBatchItemID)
    if (liquidCultureBatchItem === undefined) {
        console.log("error", getLiquidCultureBatchData(), liquidCultureBatchItem)

        throw new Error(`spawn create could not find liquidCultureBatchItem ${create.liquidCultureBatchItemID}`)
    }


    const  {batchCode, notes, startDate} = create


    const created = newSpawnBatch(
        `spawn-id-${spawnBatches.length+1}`,
        batchCode,
        numberContainers,
        weight,
        notes,
        startDate,
        liquidCultureBatchItem,
        [],
    )

    spawnBatches = [
        ...spawnBatches,
        created,
    ]

    console.log("CreateSpawnFormValues spawn batch", spawnBatches)

    const newSpawnBatchItems = arrayRange(numberContainers).map((_, i)=> newSpawnBatchItemFromIndex(autoIncrementID(), i+1, created ))


    spawnBatchItems = [...spawnBatchItems, ...newSpawnBatchItems ]

    console.log("CreateSpawnFormValues spawn batch items", spawnBatchItems)
}

export async function inMemUpdateSpawnItem(vales: UpdateSpawnBatchItemFormValues) {
    const batchFound = spawnBatches.find((batch) => batch.id === vales.batchID)
    if (batchFound === undefined) {
        throw new Error("batch not found")
    }

    batchFound.items = batchFound.items.map((item) => {
        if (item.id === vales.id) {
            return newSpawnBatchItemPartial(item.id, item.reference, item.spent, vales.contaminated)
        }

        return item
    })

    spawnBatches = spawnBatches.map((batch) => {
        if (batch.id === batchFound.id) {
            return batchFound
        }

        return batch
    })
}