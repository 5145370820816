import {CultureStarter, emptyCultureStarter} from "../culture-starter/query";

export type LiquidCultureBatch = {
    id: string
    batchCode: string
    cultureStarterID: string
    // syringe
    cultureStarter: CultureStarter
    numberOfContainers: number
    startDate: Date
    disposalDate: Date | null
    volume: number
    items: LiquidCultureBatchItemPartial[]
}

export const newLiquidCultureBatch = (
    id: string,
    batchCode: string,
    cultureStarter: CultureStarter,
    numberOfContainers: number,
    startDate: Date,
    disposalDate: Date | null,
    volume: number,
    items: LiquidCultureBatchItemPartial[] = []
): LiquidCultureBatch => {
    return {id, batchCode, cultureStarterID: cultureStarter.id, cultureStarter, numberOfContainers, startDate, disposalDate, volume, items}
}


// LiquidCultureItem represents an item created from a liquid culture batch
export type LiquidCultureBatchItem = {
    id: string
    reference: string
    batchID: string
    spent: boolean
    batch: LiquidCultureBatch
}

// relationship to the batch
export type LiquidCultureBatchItemPartial = {
    id: string
    reference: string
    spent: boolean
}

export const newLiquidCultureBatchItemPartial = (id: string, reference: string, spent: boolean): LiquidCultureBatchItemPartial => ({id, reference, spent})



export const newLiquidCultureItemFromIndex = (id: string, i: number, batch: LiquidCultureBatch):LiquidCultureBatchItem => {
    return newLiquidCultureItem(id, `${batch.batchCode}-${i}`, batch, false)
}

export const newLiquidCultureItem = (id: string, reference: string, batch: LiquidCultureBatch, spent: boolean):LiquidCultureBatchItem => {
    return {id, reference, batchID: batch.id, batch, spent}
}

export const emptyLiquidCultureBatch: LiquidCultureBatch = {
    id: "emptyLiquidCultureBatch",
    batchCode: "emptyLiquidCultureBatch",
    numberOfContainers: 0,
    startDate: new Date(),
    disposalDate: null,
    cultureStarterID: "emptyLiquidCultureBatch",
    cultureStarter: emptyCultureStarter,
    volume: 0,
    items: []
}

export const emptyLiquidCultureBatchItem: LiquidCultureBatchItem = {
    id: "emptyLiquidCultureBatchItem",
    reference: "emptyLiquidCultureBatchItem",
    batch: emptyLiquidCultureBatch,
    batchID: emptyLiquidCultureBatch.id,
    spent: false,
}
