/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BlankBatchEntity,
  BlankBatchItemPartialEntity,
  CultureStarterEntity,
  ErrorResponse,
  HarvestEntity,
  LiquidCultureBatchEntity,
  LiquidCultureBatchItemEntity,
  RecipeEntity,
  SpawnBatchEntity,
  SpawnBatchItemEntity,
  SpawnBatchItemPartialEntity,
  StrainEntity,
  SubstrateBatchEntity,
  SubstrateBatchItemEntity,
  SupplierEntity,
  UnitTypeEntity,
  UpdateBlankBatchItemRequest,
  UpdateBlankBatchRequest,
  UpdateCultureStarterRequest,
  UpdateHarvestRequest,
  UpdateLiquidCultureBatchRequest,
  UpdateRecipeRequest,
  UpdateSpawnBatchItemRequest,
  UpdateSpawnBatchRequest,
  UpdateStrainRequest,
  UpdateSubstrateBatchItemsRequest,
  UpdateSubstrateBatchRequest,
  UpdateSupplierRequest,
  UpdateUnitTypeRequest,
} from '../models/index';
import {
    BlankBatchEntityFromJSON,
    BlankBatchEntityToJSON,
    BlankBatchItemPartialEntityFromJSON,
    BlankBatchItemPartialEntityToJSON,
    CultureStarterEntityFromJSON,
    CultureStarterEntityToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HarvestEntityFromJSON,
    HarvestEntityToJSON,
    LiquidCultureBatchEntityFromJSON,
    LiquidCultureBatchEntityToJSON,
    LiquidCultureBatchItemEntityFromJSON,
    LiquidCultureBatchItemEntityToJSON,
    RecipeEntityFromJSON,
    RecipeEntityToJSON,
    SpawnBatchEntityFromJSON,
    SpawnBatchEntityToJSON,
    SpawnBatchItemEntityFromJSON,
    SpawnBatchItemEntityToJSON,
    SpawnBatchItemPartialEntityFromJSON,
    SpawnBatchItemPartialEntityToJSON,
    StrainEntityFromJSON,
    StrainEntityToJSON,
    SubstrateBatchEntityFromJSON,
    SubstrateBatchEntityToJSON,
    SubstrateBatchItemEntityFromJSON,
    SubstrateBatchItemEntityToJSON,
    SupplierEntityFromJSON,
    SupplierEntityToJSON,
    UnitTypeEntityFromJSON,
    UnitTypeEntityToJSON,
    UpdateBlankBatchItemRequestFromJSON,
    UpdateBlankBatchItemRequestToJSON,
    UpdateBlankBatchRequestFromJSON,
    UpdateBlankBatchRequestToJSON,
    UpdateCultureStarterRequestFromJSON,
    UpdateCultureStarterRequestToJSON,
    UpdateHarvestRequestFromJSON,
    UpdateHarvestRequestToJSON,
    UpdateLiquidCultureBatchRequestFromJSON,
    UpdateLiquidCultureBatchRequestToJSON,
    UpdateRecipeRequestFromJSON,
    UpdateRecipeRequestToJSON,
    UpdateSpawnBatchItemRequestFromJSON,
    UpdateSpawnBatchItemRequestToJSON,
    UpdateSpawnBatchRequestFromJSON,
    UpdateSpawnBatchRequestToJSON,
    UpdateStrainRequestFromJSON,
    UpdateStrainRequestToJSON,
    UpdateSubstrateBatchItemsRequestFromJSON,
    UpdateSubstrateBatchItemsRequestToJSON,
    UpdateSubstrateBatchRequestFromJSON,
    UpdateSubstrateBatchRequestToJSON,
    UpdateSupplierRequestFromJSON,
    UpdateSupplierRequestToJSON,
    UpdateUnitTypeRequestFromJSON,
    UpdateUnitTypeRequestToJSON,
} from '../models/index';

export interface DeleteBlankBatchByIDRequest {
    id: string;
    updateBlankBatchRequest?: UpdateBlankBatchRequest;
}

export interface DeleteCultureStarterByIDRequest {
    id: string;
    updateCultureStarterRequest?: UpdateCultureStarterRequest;
}

export interface DeleteHarvestByIDRequest {
    id: string;
    updateHarvestRequest?: UpdateHarvestRequest;
}

export interface DeleteLiquidCultureBatchByIDRequest {
    id: string;
}

export interface DeleteLiquidCultureBatchItemsByIDRequest {
    id: string;
}

export interface DeleteRecipeByIDRequest {
    id: string;
    updateRecipeRequest?: UpdateRecipeRequest;
}

export interface DeleteSpawnBatchByIDRequest {
    id: string;
    updateSpawnBatchRequest?: UpdateSpawnBatchRequest;
}

export interface DeleteStrainByIDRequest {
    id: string;
    updateStrainRequest?: UpdateStrainRequest;
}

export interface DeleteSubstrateBatchByIDRequest {
    id: string;
    updateSubstrateBatchRequest?: UpdateSubstrateBatchRequest;
}

export interface DeleteSupplierByIDRequest {
    id: string;
}

export interface DeleteUnitTypeByIDRequest {
    id: string;
    updateUnitTypeRequest?: UpdateUnitTypeRequest;
}

export interface GetAllLiquidCultureBatchItemsByBatchIDRequest {
    id: string;
}

export interface GetBlankBatchByIDRequest {
    id: string;
}

export interface GetCultureStarterByIDRequest {
    id: string;
}

export interface GetHarvestByIDRequest {
    id: string;
}

export interface GetLiquidCultureBatchByIDRequest {
    id: string;
}

export interface GetLiquidCultureBatchItemsByIDRequest {
    id: string;
}

export interface GetRecipeByIDRequest {
    id: string;
}

export interface GetSpawnBatchByIDRequest {
    id: string;
}

export interface GetStrainByIDRequest {
    id: string;
}

export interface GetSubstrateBatchByIDRequest {
    id: string;
}

export interface GetSupplierByIDRequest {
    id: string;
}

export interface GetUnitTypeByIDRequest {
    id: string;
}

export interface UpdateBlankBatchByIDRequest {
    id: string;
    updateBlankBatchRequest?: UpdateBlankBatchRequest;
}

export interface UpdateBlankBatchItemByIDRequest {
    id: string;
    itemId: string;
    updateBlankBatchItemRequest?: UpdateBlankBatchItemRequest;
}

export interface UpdateCultureStarterByIDRequest {
    id: string;
    updateCultureStarterRequest?: UpdateCultureStarterRequest;
}

export interface UpdateHarvestByIDRequest {
    id: string;
    updateHarvestRequest?: UpdateHarvestRequest;
}

export interface UpdateLiquidCultureBatchByIDRequest {
    id: string;
    updateLiquidCultureBatchRequest?: UpdateLiquidCultureBatchRequest;
}

export interface UpdateRecipeByIDRequest {
    id: string;
    updateRecipeRequest?: UpdateRecipeRequest;
}

export interface UpdateSpawnBatchByIDRequest {
    id: string;
    updateSpawnBatchRequest?: UpdateSpawnBatchRequest;
}

export interface UpdateSpawnBatchItemOperationRequest {
    id: string;
    updateSpawnBatchItemRequest?: UpdateSpawnBatchItemRequest;
}

export interface UpdateStrainByIDRequest {
    id: string;
    updateStrainRequest?: UpdateStrainRequest;
}

export interface UpdateSubstrateBatchByIDRequest {
    id: string;
    updateSubstrateBatchRequest?: UpdateSubstrateBatchRequest;
}

export interface UpdateSubstrateBatchUnitsRequest {
    id: string;
    updateSubstrateBatchItemsRequest?: UpdateSubstrateBatchItemsRequest;
}

export interface UpdateSupplierByIDRequest {
    id: string;
    updateSupplierRequest?: UpdateSupplierRequest;
}

export interface UpdateUnitTypeByIDRequest {
    id: string;
    updateUnitTypeRequest?: UpdateUnitTypeRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Delete BlankBatch by ID
     */
    async deleteBlankBatchByIDRaw(requestParameters: DeleteBlankBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlankBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBlankBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/blank-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBlankBatchRequestToJSON(requestParameters.updateBlankBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlankBatchEntityFromJSON(jsonValue));
    }

    /**
     * Delete BlankBatch by ID
     */
    async deleteBlankBatchByID(requestParameters: DeleteBlankBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlankBatchEntity> {
        const response = await this.deleteBlankBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete CultureStarter by Id
     */
    async deleteCultureStarterByIDRaw(requestParameters: DeleteCultureStarterByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CultureStarterEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCultureStarterByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/culture-starter/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCultureStarterRequestToJSON(requestParameters.updateCultureStarterRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CultureStarterEntityFromJSON(jsonValue));
    }

    /**
     * Delete CultureStarter by Id
     */
    async deleteCultureStarterByID(requestParameters: DeleteCultureStarterByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CultureStarterEntity> {
        const response = await this.deleteCultureStarterByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Harvest by ID
     */
    async deleteHarvestByIDRaw(requestParameters: DeleteHarvestByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HarvestEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteHarvestByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/harvests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHarvestRequestToJSON(requestParameters.updateHarvestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HarvestEntityFromJSON(jsonValue));
    }

    /**
     * Delete Harvest by ID
     */
    async deleteHarvestByID(requestParameters: DeleteHarvestByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HarvestEntity> {
        const response = await this.deleteHarvestByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete LiquidCultureBatch by Id
     */
    async deleteLiquidCultureBatchByIDRaw(requestParameters: DeleteLiquidCultureBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiquidCultureBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLiquidCultureBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/liquid-culture-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiquidCultureBatchEntityFromJSON(jsonValue));
    }

    /**
     * Delete LiquidCultureBatch by Id
     */
    async deleteLiquidCultureBatchByID(requestParameters: DeleteLiquidCultureBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiquidCultureBatchEntity> {
        const response = await this.deleteLiquidCultureBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete LiquidCultureBatchItems by Id
     */
    async deleteLiquidCultureBatchItemsByIDRaw(requestParameters: DeleteLiquidCultureBatchItemsByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiquidCultureBatchItemEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLiquidCultureBatchItemsByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/liquid-culture-batch-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiquidCultureBatchItemEntityFromJSON(jsonValue));
    }

    /**
     * Delete LiquidCultureBatchItems by Id
     */
    async deleteLiquidCultureBatchItemsByID(requestParameters: DeleteLiquidCultureBatchItemsByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiquidCultureBatchItemEntity> {
        const response = await this.deleteLiquidCultureBatchItemsByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Recipe by Id
     */
    async deleteRecipeByIDRaw(requestParameters: DeleteRecipeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecipeEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRecipeByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/recipes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRecipeRequestToJSON(requestParameters.updateRecipeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipeEntityFromJSON(jsonValue));
    }

    /**
     * Delete Recipe by Id
     */
    async deleteRecipeByID(requestParameters: DeleteRecipeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecipeEntity> {
        const response = await this.deleteRecipeByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete SpawnBatch by Id
     */
    async deleteSpawnBatchByIDRaw(requestParameters: DeleteSpawnBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpawnBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSpawnBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/spawn-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSpawnBatchRequestToJSON(requestParameters.updateSpawnBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpawnBatchEntityFromJSON(jsonValue));
    }

    /**
     * Delete SpawnBatch by Id
     */
    async deleteSpawnBatchByID(requestParameters: DeleteSpawnBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpawnBatchEntity> {
        const response = await this.deleteSpawnBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Strain by Id
     */
    async deleteStrainByIDRaw(requestParameters: DeleteStrainByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrainEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteStrainByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/strains/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStrainRequestToJSON(requestParameters.updateStrainRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrainEntityFromJSON(jsonValue));
    }

    /**
     * Delete Strain by Id
     */
    async deleteStrainByID(requestParameters: DeleteStrainByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrainEntity> {
        const response = await this.deleteStrainByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete SubstrateBatch by Id
     */
    async deleteSubstrateBatchByIDRaw(requestParameters: DeleteSubstrateBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubstrateBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSubstrateBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/substrate-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubstrateBatchRequestToJSON(requestParameters.updateSubstrateBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubstrateBatchEntityFromJSON(jsonValue));
    }

    /**
     * Delete SubstrateBatch by Id
     */
    async deleteSubstrateBatchByID(requestParameters: DeleteSubstrateBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubstrateBatchEntity> {
        const response = await this.deleteSubstrateBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Supplier by Id
     */
    async deleteSupplierByIDRaw(requestParameters: DeleteSupplierByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteSupplierByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/suppliers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierEntityFromJSON(jsonValue));
    }

    /**
     * Delete Supplier by Id
     */
    async deleteSupplierByID(requestParameters: DeleteSupplierByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierEntity> {
        const response = await this.deleteSupplierByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete UnitType by Id
     */
    async deleteUnitTypeByIDRaw(requestParameters: DeleteUnitTypeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnitTypeEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUnitTypeByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/unit-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUnitTypeRequestToJSON(requestParameters.updateUnitTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnitTypeEntityFromJSON(jsonValue));
    }

    /**
     * Delete UnitType by Id
     */
    async deleteUnitTypeByID(requestParameters: DeleteUnitTypeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnitTypeEntity> {
        const response = await this.deleteUnitTypeByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get LiquidCultureBatchItems
     */
    async getAllLiquidCultureBatchItemsByBatchIDRaw(requestParameters: GetAllLiquidCultureBatchItemsByBatchIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LiquidCultureBatchItemEntity>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllLiquidCultureBatchItemsByBatchID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/liquid-culture-batches/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LiquidCultureBatchItemEntityFromJSON));
    }

    /**
     * Get LiquidCultureBatchItems
     */
    async getAllLiquidCultureBatchItemsByBatchID(requestParameters: GetAllLiquidCultureBatchItemsByBatchIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LiquidCultureBatchItemEntity>> {
        const response = await this.getAllLiquidCultureBatchItemsByBatchIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All Sopawn Batch Items
     */
    async getAllSpawnBatchItemsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpawnBatchItemEntity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/spawn-batch-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpawnBatchItemEntityFromJSON));
    }

    /**
     * Get All Sopawn Batch Items
     */
    async getAllSpawnBatchItems(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpawnBatchItemEntity>> {
        const response = await this.getAllSpawnBatchItemsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get BlankBatch by ID
     */
    async getBlankBatchByIDRaw(requestParameters: GetBlankBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlankBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBlankBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/blank-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlankBatchEntityFromJSON(jsonValue));
    }

    /**
     * Get BlankBatch by ID
     */
    async getBlankBatchByID(requestParameters: GetBlankBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlankBatchEntity> {
        const response = await this.getBlankBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get CultureStarter by Id
     */
    async getCultureStarterByIDRaw(requestParameters: GetCultureStarterByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CultureStarterEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCultureStarterByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/culture-starter/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CultureStarterEntityFromJSON(jsonValue));
    }

    /**
     * Get CultureStarter by Id
     */
    async getCultureStarterByID(requestParameters: GetCultureStarterByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CultureStarterEntity> {
        const response = await this.getCultureStarterByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Harvest by ID
     */
    async getHarvestByIDRaw(requestParameters: GetHarvestByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HarvestEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getHarvestByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/harvests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HarvestEntityFromJSON(jsonValue));
    }

    /**
     * Get Harvest by ID
     */
    async getHarvestByID(requestParameters: GetHarvestByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HarvestEntity> {
        const response = await this.getHarvestByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get LiquidCultureBatch by Id
     */
    async getLiquidCultureBatchByIDRaw(requestParameters: GetLiquidCultureBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiquidCultureBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLiquidCultureBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/liquid-culture-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiquidCultureBatchEntityFromJSON(jsonValue));
    }

    /**
     * Get LiquidCultureBatch by Id
     */
    async getLiquidCultureBatchByID(requestParameters: GetLiquidCultureBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiquidCultureBatchEntity> {
        const response = await this.getLiquidCultureBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get LiquidCultureBatchItems by Id
     */
    async getLiquidCultureBatchItemsByIDRaw(requestParameters: GetLiquidCultureBatchItemsByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiquidCultureBatchItemEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLiquidCultureBatchItemsByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/liquid-culture-batch-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiquidCultureBatchItemEntityFromJSON(jsonValue));
    }

    /**
     * Get LiquidCultureBatchItems by Id
     */
    async getLiquidCultureBatchItemsByID(requestParameters: GetLiquidCultureBatchItemsByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiquidCultureBatchItemEntity> {
        const response = await this.getLiquidCultureBatchItemsByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Recipe by Id
     */
    async getRecipeByIDRaw(requestParameters: GetRecipeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecipeEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRecipeByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/recipes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipeEntityFromJSON(jsonValue));
    }

    /**
     * Get Recipe by Id
     */
    async getRecipeByID(requestParameters: GetRecipeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecipeEntity> {
        const response = await this.getRecipeByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get SpawnBatch by Id
     */
    async getSpawnBatchByIDRaw(requestParameters: GetSpawnBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpawnBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSpawnBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/spawn-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpawnBatchEntityFromJSON(jsonValue));
    }

    /**
     * Get SpawnBatch by Id
     */
    async getSpawnBatchByID(requestParameters: GetSpawnBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpawnBatchEntity> {
        const response = await this.getSpawnBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Strain by Id
     */
    async getStrainByIDRaw(requestParameters: GetStrainByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrainEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStrainByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/strains/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrainEntityFromJSON(jsonValue));
    }

    /**
     * Get Strain by Id
     */
    async getStrainByID(requestParameters: GetStrainByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrainEntity> {
        const response = await this.getStrainByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get SubstrateBatch by Id
     */
    async getSubstrateBatchByIDRaw(requestParameters: GetSubstrateBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubstrateBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSubstrateBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/substrate-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubstrateBatchEntityFromJSON(jsonValue));
    }

    /**
     * Get SubstrateBatch by Id
     */
    async getSubstrateBatchByID(requestParameters: GetSubstrateBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubstrateBatchEntity> {
        const response = await this.getSubstrateBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Supplier by Id
     */
    async getSupplierByIDRaw(requestParameters: GetSupplierByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSupplierByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/suppliers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierEntityFromJSON(jsonValue));
    }

    /**
     * Get Supplier by Id
     */
    async getSupplierByID(requestParameters: GetSupplierByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierEntity> {
        const response = await this.getSupplierByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get UnitType by Id
     */
    async getUnitTypeByIDRaw(requestParameters: GetUnitTypeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnitTypeEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUnitTypeByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/unit-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnitTypeEntityFromJSON(jsonValue));
    }

    /**
     * Get UnitType by Id
     */
    async getUnitTypeByID(requestParameters: GetUnitTypeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnitTypeEntity> {
        const response = await this.getUnitTypeByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update BlankBatch by ID
     */
    async updateBlankBatchByIDRaw(requestParameters: UpdateBlankBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlankBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBlankBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/blank-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBlankBatchRequestToJSON(requestParameters.updateBlankBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlankBatchEntityFromJSON(jsonValue));
    }

    /**
     * Update BlankBatch by ID
     */
    async updateBlankBatchByID(requestParameters: UpdateBlankBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlankBatchEntity> {
        const response = await this.updateBlankBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update BlankBatchItem by ID
     */
    async updateBlankBatchItemByIDRaw(requestParameters: UpdateBlankBatchItemByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlankBatchItemPartialEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBlankBatchItemByID.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling updateBlankBatchItemByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/blank-batches/{id}/items/{itemId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"itemId"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBlankBatchItemRequestToJSON(requestParameters.updateBlankBatchItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlankBatchItemPartialEntityFromJSON(jsonValue));
    }

    /**
     * Update BlankBatchItem by ID
     */
    async updateBlankBatchItemByID(requestParameters: UpdateBlankBatchItemByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlankBatchItemPartialEntity> {
        const response = await this.updateBlankBatchItemByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update CultureStarter by Id
     */
    async updateCultureStarterByIDRaw(requestParameters: UpdateCultureStarterByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CultureStarterEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCultureStarterByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/culture-starter/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCultureStarterRequestToJSON(requestParameters.updateCultureStarterRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CultureStarterEntityFromJSON(jsonValue));
    }

    /**
     * Update CultureStarter by Id
     */
    async updateCultureStarterByID(requestParameters: UpdateCultureStarterByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CultureStarterEntity> {
        const response = await this.updateCultureStarterByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Harvest by ID
     */
    async updateHarvestByIDRaw(requestParameters: UpdateHarvestByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HarvestEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateHarvestByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/harvests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHarvestRequestToJSON(requestParameters.updateHarvestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HarvestEntityFromJSON(jsonValue));
    }

    /**
     * Update Harvest by ID
     */
    async updateHarvestByID(requestParameters: UpdateHarvestByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HarvestEntity> {
        const response = await this.updateHarvestByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update LiquidCultureBatch by Id
     */
    async updateLiquidCultureBatchByIDRaw(requestParameters: UpdateLiquidCultureBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiquidCultureBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLiquidCultureBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/liquid-culture-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLiquidCultureBatchRequestToJSON(requestParameters.updateLiquidCultureBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiquidCultureBatchEntityFromJSON(jsonValue));
    }

    /**
     * Update LiquidCultureBatch by Id
     */
    async updateLiquidCultureBatchByID(requestParameters: UpdateLiquidCultureBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiquidCultureBatchEntity> {
        const response = await this.updateLiquidCultureBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Recipe by Id
     */
    async updateRecipeByIDRaw(requestParameters: UpdateRecipeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecipeEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRecipeByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/recipes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRecipeRequestToJSON(requestParameters.updateRecipeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipeEntityFromJSON(jsonValue));
    }

    /**
     * Update Recipe by Id
     */
    async updateRecipeByID(requestParameters: UpdateRecipeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecipeEntity> {
        const response = await this.updateRecipeByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update SpawnBatch by Id
     */
    async updateSpawnBatchByIDRaw(requestParameters: UpdateSpawnBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpawnBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSpawnBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/spawn-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSpawnBatchRequestToJSON(requestParameters.updateSpawnBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpawnBatchEntityFromJSON(jsonValue));
    }

    /**
     * Update SpawnBatch by Id
     */
    async updateSpawnBatchByID(requestParameters: UpdateSpawnBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpawnBatchEntity> {
        const response = await this.updateSpawnBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Spawn Batch Item
     */
    async updateSpawnBatchItemRaw(requestParameters: UpdateSpawnBatchItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpawnBatchItemPartialEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSpawnBatchItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/spawn-batch-items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSpawnBatchItemRequestToJSON(requestParameters.updateSpawnBatchItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpawnBatchItemPartialEntityFromJSON(jsonValue));
    }

    /**
     * Update Spawn Batch Item
     */
    async updateSpawnBatchItem(requestParameters: UpdateSpawnBatchItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpawnBatchItemPartialEntity> {
        const response = await this.updateSpawnBatchItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Strain by Id
     */
    async updateStrainByIDRaw(requestParameters: UpdateStrainByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StrainEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateStrainByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/strains/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStrainRequestToJSON(requestParameters.updateStrainRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StrainEntityFromJSON(jsonValue));
    }

    /**
     * Update Strain by Id
     */
    async updateStrainByID(requestParameters: UpdateStrainByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StrainEntity> {
        const response = await this.updateStrainByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update SubstrateBatch by Id
     */
    async updateSubstrateBatchByIDRaw(requestParameters: UpdateSubstrateBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubstrateBatchEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSubstrateBatchByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/substrate-batches/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubstrateBatchRequestToJSON(requestParameters.updateSubstrateBatchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubstrateBatchEntityFromJSON(jsonValue));
    }

    /**
     * Update SubstrateBatch by Id
     */
    async updateSubstrateBatchByID(requestParameters: UpdateSubstrateBatchByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubstrateBatchEntity> {
        const response = await this.updateSubstrateBatchByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update all the substrate batch item by ID
     */
    async updateSubstrateBatchUnitsRaw(requestParameters: UpdateSubstrateBatchUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubstrateBatchItemEntity>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSubstrateBatchUnits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/substrate-batches/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubstrateBatchItemsRequestToJSON(requestParameters.updateSubstrateBatchItemsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubstrateBatchItemEntityFromJSON));
    }

    /**
     * Update all the substrate batch item by ID
     */
    async updateSubstrateBatchUnits(requestParameters: UpdateSubstrateBatchUnitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubstrateBatchItemEntity>> {
        const response = await this.updateSubstrateBatchUnitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Supplier by Id
     */
    async updateSupplierByIDRaw(requestParameters: UpdateSupplierByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupplierEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateSupplierByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/suppliers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSupplierRequestToJSON(requestParameters.updateSupplierRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierEntityFromJSON(jsonValue));
    }

    /**
     * Update Supplier by Id
     */
    async updateSupplierByID(requestParameters: UpdateSupplierByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupplierEntity> {
        const response = await this.updateSupplierByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update UnitType by Id
     */
    async updateUnitTypeByIDRaw(requestParameters: UpdateUnitTypeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnitTypeEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUnitTypeByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/unit-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUnitTypeRequestToJSON(requestParameters.updateUnitTypeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnitTypeEntityFromJSON(jsonValue));
    }

    /**
     * Update UnitType by Id
     */
    async updateUnitTypeByID(requestParameters: UpdateUnitTypeByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnitTypeEntity> {
        const response = await this.updateUnitTypeByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
