import {useAuthToken} from "../../query/jwt";
import React, {ChangeEvent, useState} from "react";
import {emptyNotifications, newNotification, Notifications} from "../../components/Notifications/notifications";
import {FormGroup} from "../../components/form/field";
import {DateInput} from "../../components/form/DateInput";
import {Form, Formik} from "formik";
import {disableSubmit} from "../../components/form/validation";
import {FormErrors} from "../../components/form/errors";
import * as yup from "yup";
import {Form as UIForm} from "semantic-ui-react";
import {SubstrateBatchV2} from "../../query/substrate/entities";
import {useMutation, useQueryClient} from "react-query";
import {updateStrainCreateFormCache} from "../../query/strain/query";
import queryKeys from "../../query/keys";
import {authedUpdateSubstrateBatch} from "../../query/substrate/query";

export type UpdateSubstrateBatchFormValues = {
    createdDate: Date
}

type CreateSubstrateBatchFormProps = {
    batch: SubstrateBatchV2
    onSuccess: () => void
}

export function UpdateSubstrateBatchForm({batch, onSuccess}: CreateSubstrateBatchFormProps ) {
    const getAuthToken = useAuthToken()
    const [notifications, setNotifications] = useState(emptyNotifications);

    const queryClient = useQueryClient()
    const { mutate: mutateCreateStrain } = useMutation(authedUpdateSubstrateBatch(getAuthToken()), {
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.substrateBatchesGet);
            onSuccess()
        },
        onError: (err) => {
            setNotifications((notifactions) => [...notifactions, newNotification("error", `create strain error: ${err}`)]  )
        }
    });

    // form values
    const [createdDate, setCreatedDate]  = useState(batch.createdDate)

    const dateHasNotChanged = createdDate.toDateString() == batch.createdDate.toDateString()

    return (
        <Formik
            initialValues={{createdDate}}
            validationSchema={buildFormSchema()}
            onSubmit={(formValues: UpdateSubstrateBatchFormValues, {setSubmitting}) => {
                setSubmitting(true)
                // mutate
                mutateCreateStrain({id: batch.id, createdDate})

            }}
        >
            {({
                  errors,
              }) => {
                return (
                    <Form className={"ui form"}>
                        <Notifications notifications={notifications} clearAll={() => setNotifications([])}/>
                        <FormErrors errors={JSON.stringify(errors)}/>

                        <FormGroup>
                            <DateInput name={"createdDate"} date={createdDate} onChange={setCreatedDate} label={"Created Date"} touched={true}/>
                        </FormGroup>
                        <UIForm.Button
                            positive
                            disabled={dateHasNotChanged}
                            type={"submit"}>Update
                        </UIForm.Button>
                    </Form>
                )
            }}
        </Formik>
    )
}


function buildFormSchema() {
    return yup.object().shape({});
}