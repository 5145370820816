import {BasicModal} from "../../components/modal/basic";
import {Button, Form as UIForm, Header, Modal} from "semantic-ui-react";
import React, {useState} from "react";
import {useModal} from "../../hooks/useModal";
import {formatDateString} from "../../components/form/DateInput";
import {SpawnBatchItemPartial} from "../../query/spawn-batch/entities";
import {useMutation, useQueryClient} from "react-query";
import queryKeys from "../../query/keys";
import {newNotification} from "../../components/Notifications/notifications";
import {useAuthToken} from "../../query/jwt";
import {authedCreateSubstrateBatch} from "../../query/substrate/query";


type ConfirmModalProps = {
    spawnBatchItem: SpawnBatchItemPartial | undefined
    blankBatchItemIds: string[]
    createdDate: Date
    batchCode: string
    onSuccess: () => void
    onError: (err: string) => void
}



// one day this could be made more general

export function ConfirmModal({spawnBatchItem, blankBatchItemIds, createdDate, batchCode, onSuccess, onError}: ConfirmModalProps) {
    const {isOpen, onClose, onOpen} = useModal()
    const getAuthToken  = useAuthToken()
    const queryClient = useQueryClient()
    const [submitting, setSubmitting] = useState(false)

    const label = "Create Substrate Batch"

    const disabled = spawnBatchItem === undefined || blankBatchItemIds.length === 0 || batchCode === ""

    console.log("confirm modal", spawnBatchItem, blankBatchItemIds, createdDate, batchCode)

    const { mutate: createAction } = useMutation(authedCreateSubstrateBatch(getAuthToken()), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.substrateBatchesGet);
            await queryClient.invalidateQueries(queryKeys.blankBatchesWithItems);
            await queryClient.invalidateQueries(queryKeys.spawnBatchesGet);
            onSuccess()
            setSubmitting(false)
            onClose()
        },
        onError: (err) => onError(`create substrate batch error: ${err}`)
    });

    return (<Modal
        className={"pad-left-10"}
        closeIcon
        open={isOpen}
        trigger={<UIForm.Button positive type={"submit"} disabled={disabled}>{label}</UIForm.Button>}
        onClose={onClose}
        onOpen={onOpen}
        size={"small"}
    >
        <Header icon='address card' content={label} />
        <Modal.Content>
            <p>Are you sure you want to create a batch?</p>
            <p>Batch Code: <b>{batchCode}</b></p>
            <p>Spawn Batch Item: <b>{spawnBatchItem?.reference}</b></p>
            <p>With <b>{blankBatchItemIds.length}</b> Blank Batch Items</p>
            <p>Created Date: <b>{formatDateString(createdDate)}</b></p>
        </Modal.Content>
        <Modal.Actions>
            <Button disabled={submitting} size={"small"} positive onClick={() => {
                setSubmitting(true)
                createAction({
                    spawnBatchItemId: spawnBatchItem === undefined ? "" : spawnBatchItem.id,
                    blankBatchItemIds,
                    createdDate,
                    batchCode,
                })
            }}>Create</Button>
            <Button size={"small"} color={"red"}  onClick={onClose}>Cancel</Button>
        </Modal.Actions>
    </Modal>
    )
}
