/* tslint:disable */
/* eslint-disable */
/**
 * SporeDrive API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: m.kaschula@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * create substrateBatch request object
 * @export
 * @interface UpdateSubstrateBatchRequest
 */
export interface UpdateSubstrateBatchRequest {
    /**
     * 
     * @type {Date}
     * @memberof UpdateSubstrateBatchRequest
     */
    createdDate: Date;
}

/**
 * Check if a given object implements the UpdateSubstrateBatchRequest interface.
 */
export function instanceOfUpdateSubstrateBatchRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;

    return isInstance;
}

export function UpdateSubstrateBatchRequestFromJSON(json: any): UpdateSubstrateBatchRequest {
    return UpdateSubstrateBatchRequestFromJSONTyped(json, false);
}

export function UpdateSubstrateBatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSubstrateBatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': (new Date(json['createdDate'])),
    };
}

export function UpdateSubstrateBatchRequestToJSON(value?: UpdateSubstrateBatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': (value.createdDate.toISOString()),
    };
}

