import {
    inMemCreateSubstrateBatchV2,
    inMemGetSubstrateBatchesWithItemsV2, inMemUpdateSubstrateBatchV2,
} from "./inmemory-batch";
import {SubstrateBatchV2} from "./entities";
import {
    sdCreateSubstrateBatch,
    sdGetSubstrateBatchesWithItems, sdUpdateSubstrateBatch,
} from "./sdapi";
import {JWTToken} from "../jwt";
import {isEnvIntegration, isEnvProduction} from "../../env";
import {UpdateSubstrateBatchFormValues} from "../../pages/substrate/edit-form";


// APIS
export async function getSubstrateBatchesWithItems(token: Promise<JWTToken> ): Promise<SubstrateBatchV2[]> {
    if ( isEnvProduction() || isEnvIntegration() ) {
        return sdGetSubstrateBatchesWithItems(token)
    }

    return inMemGetSubstrateBatchesWithItemsV2()
}

export type CreateSubstrateBatchFormValuesV2 = {
    spawnBatchItemId: string;
    blankBatchItemIds: string[];
    createdDate: Date;
    batchCode: string;
}


export function authedCreateSubstrateBatch(token: Promise<JWTToken>) {
    return async function (values: CreateSubstrateBatchFormValuesV2): Promise<void> {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdCreateSubstrateBatch(token, values)
            return
        }

        return inMemCreateSubstrateBatchV2(values)
    }
}


export type UpdateSubstrateBatchFormValuesWithID = {
    id: string
    createdDate: Date
}

export function authedUpdateSubstrateBatch(token: Promise<JWTToken>) {
    return async function (values: UpdateSubstrateBatchFormValuesWithID): Promise<void> {
        if (isEnvProduction() || isEnvIntegration()) {
            await sdUpdateSubstrateBatch(token, values)
            return
        }

        return inMemUpdateSubstrateBatchV2(values)
    }
}
